<template>
  <div>
    <v-form
      ref="form"
      class="pa-4"
      v-model="formValid"
      lazy-validation
      @submit.prevent="getPaymentDetails"
    >
      <div class="d-flex align-center flex-wrap">
        <div class="mx-1">
          <div class="pb-1 fsize14 secondaryColor">UserId</div>
          <v-text-field
            v-model="userId"
            dense
            outlined
            menu-props="offset-y"
            placeholder="Enter the UserId"
          >
          </v-text-field>
        </div>
        <div class="mx-1">
          <div class="pb-1 fsize14 secondaryColor">*Type</div>
          <v-autocomplete
            @change="resetData()"
            v-model="payType"
            dense
            outlined
            menu-props="offset-y"
            placeholder="select the status"
            :items="['payIn', 'payOut']"
            :rules="typeRules"
          ></v-autocomplete>
        </div>

        <div class="mx-1" v-if="payType == 'payIn'">
          <div class="pb-1 fsize14 secondaryColor">*Status</div>
          <v-autocomplete
            v-model="status"
            dense
            outlined
            menu-props="offset-y"
            placeholder="select the status"
            :items="statusList"
            :rules="statusRules"
          ></v-autocomplete>
        </div>

        <div class="mx-1">
          <div class="pb-1 fsize14 secondaryColor">*From Date</div>
          <v-menu
            v-model="dateMenu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @click:append="dateMenu1 = true"
                clearable
                class="apiKeyInput"
                v-model="computedDateFormatted1"
                outlined
                persistent-hint
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="fromDateRules"
                placeholder="Select the From Date"
                append-icon="mdi-calendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="new Date().toISOString().substr(0, 10)"
              v-model="fromDate"
              :show-current="true"
              no-title
              @input="dateMenu1 = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="mx-1">
          <div class="pb-1 fsize14 secondaryColor">*To Date</div>
          <v-menu
            v-model="dateMenu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @click:append="dateMenu2 = true"
                clearable
                class="apiKeyInput"
                v-model="computedDateFormatted2"
                outlined
                persistent-hint
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="toDateRules"
                placeholder="Select the To Date"
                append-icon="mdi-calendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="new Date().toISOString().substr(0, 10)"
              v-model="toDate"
              :show-current="true"
              no-title
              @input="dateMenu2 = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="mx-1">
          <div class="pb-1 fsize14 secondaryColor">From Amount</div>
          <v-text-field
            v-model="fromAmount"
            dense
            outlined
            menu-props="offset-y"
            placeholder="E.g:1000"
          ></v-text-field>
        </div>
        <div class="mx-1">
          <div class="pb-1 fsize14 secondaryColor">To Amount</div>
          <v-text-field
            v-model="toAmount"
            dense
            outlined
            menu-props="offset-y"
            placeholder="E.g:10000"
          ></v-text-field>
        </div>
        <div class="mx-1" v-if="payType == 'payIn'">
          <div class="pb-1 fsize14 secondaryColor">Payment mode</div>
          <v-text-field
            v-model="paymentMode"
            dense
            outlined
            menu-props="offset-y"
            placeholder="E.g:UPI"
          ></v-text-field>
        </div>
        <div class="mx-1">
          <div class="pb-1 fsize14 secondaryColor">Exchange Segment</div>
          <v-text-field
            v-model="exchangeSegment"
            dense
            outlined
            menu-props="offset-y"
            placeholder="E.g:NSE"
          >
          </v-text-field>
        </div>

        <div class="mx-1">
          <v-btn
            @click="resetData()"
            type="submit"
            depressed
            height="40"
            color="primary"
            class="text-capitalize fsize14"
            >Submit</v-btn
          >
        </div>
        <v-spacer></v-spacer>

        <div
          class="d-flex flex-end pa-4"
          v-if="
            payType == 'payIn'
              ? paymentDetails.length > 0
              : payOutDetails.length > 0
          "
        >
          <v-btn
            height="32"
            @click="
              DownloadNewFormet(
                payType == 'payIn' ? paymentDetails : payOutDetails,
                payType
              )
            "
            depressed
            min-width="fit-content"
            color="primary"
            class="text-capitalize"
          >
            Download <v-icon class="mx-2 fsize18">mdi-cloud-download </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="d-flex align-center">
        <!-- <div class="mx-3">
          <div class="pb-1 fsize14 secondaryColor">From Amount</div>
          <v-text-field v-model="fromAmount" dense outlined menu-props="offset-y" placeholder="E.g:1000"></v-text-field>
        </div>
        <div class="mx-3">
          <div class="pb-1 fsize14 secondaryColor">To Amount</div>
          <v-text-field v-model="toAmount" dense outlined menu-props="offset-y" placeholder="E.g:10000"></v-text-field>
        </div>
        <div class="mx-3" v-if="payType == 'payIn'">
          <div class="pb-1 fsize14 secondaryColor">Payment mode</div>
          <v-text-field v-model="paymentMode" dense outlined menu-props="offset-y" placeholder="E.g:UPI"></v-text-field>
        </div>
        <div class="mx-3">
          <div class="pb-1 fsize14 secondaryColor">Exchange Segment</div>
          <v-text-field v-model="exchangeSegment" dense outlined menu-props="offset-y" placeholder="E.g:NSE">
          </v-text-field>
        </div> -->
      </div>
    </v-form>
    <div v-if="payType == 'payIn'">
      <v-simple-table v-if="paymentDetails.length > 0" class="my-5">
        <thead>
          <tr class="tableRow">
            <th class="fsize12 font-weight-normal tableContent no-wrap">
              User Id
            </th>
            <th
              class="text-center fsize12 font-weight-normal tableContent no-wrap"
            >
              Status
            </th>
            <th
              class="text-right fsize12 font-weight-normal tableContent no-wrap"
            >
              Amount
            </th>
            <th class="fsize12 font-weight-normal tableContent no-wrap">
              Payment Mode
            </th>
            <th class="fsize12 font-weight-normal tableContent no-wrap">
              Exchange Segment
            </th>
            <th
              class="text-left fsize12 font-weight-normal tableContent no-wrap"
            >
              AC Number
            </th>
            <th
              class="text-left fsize12 font-weight-normal tableContent no-wrap"
            >
              Order Id
            </th>
            <th
              class="text-left fsize12 font-weight-normal tableContent no-wrap"
            >
              Date
            </th>
            <th
              class="text-left fsize12 font-weight-normal tableContent no-wrap"
            >
              Request
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="tableRow" v-for="(i, idex) in paymentDetails" :key="idex">
            <td class="tableContent no-wrap">{{ i.userId }}</td>
            <td class="text-center tableContent">{{ i.paymentStatus }}</td>
            <td class="text-right tableContent">
              {{ i.amount ? Number(i.amount).toFixed(2) : i.amount }}
            </td>
            <td class="tableContent">{{ i.paymentMethod }}</td>
            <td class="tableContent no-wrap">{{ i.segment }}</td>
            <td class="tableContent">{{ i.accountNo }}</td>
            <td class="text-left tableContent">{{ i.orderId }}</td>
            <td class="text-left tableContent no-wrap">{{ i.createdOn }}</td>
            <td
              class="text-left tableContent input_text1"
              @click="callDialog(i.request)"
            >
              {{ i.request }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div
        v-else-if="isSubmit"
        class="d-flex justify-center secondaryColor pa-8"
      >
        No Data Found
      </div>
      <div
        v-if="paymentDetails.length != 0"
        class="d-flex align-center justify-end pa-4"
      >
        <v-pagination
          v-model="page"
          :length="length"
          :total-visible="7"
          circle
        ></v-pagination>
      </div>
    </div>
    <div v-if="payType == 'payOut'">
      <v-simple-table
        id="tableData"
        v-if="payOutDetails.length > 0"
        class="my-5"
      >
        <thead>
          <tr class="tableRow">
            <th class="fsize12 font-weight-normal tableContent no-wrap">
              User Id
            </th>
            <th
              class="text-right fsize12 font-weight-normal tableContent no-wrap"
            >
              Amount
            </th>
            <th
              class="text-center fsize12 font-weight-normal tableContent no-wrap"
            >
              Exchange Segment
            </th>
            <th class="fsize12 font-weight-normal tableContent no-wrap">
              AC Number
            </th>
            <th class="fsize12 font-weight-normal tableContent no-wrap">
              Date
            </th>
            <th
              class="text-center fsize12 font-weight-normal tableContent no-wrap"
            >
              PayOut Reason
            </th>
            <th
              class="text-left fsize12 font-weight-normal tableContent no-wrap"
            >
              Request
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="tableRow" v-for="(i, idex) in payOutDetails" :key="idex">
            <td class="tableContent no-wrap">{{ i.userId }}</td>
            <td class="text-right tableContent">
              {{ i.amount ? Number(i.amount).toFixed(2) : i.amount }}
            </td>
            <td class="text-center tableContent">{{ i.segment }}</td>
            <td class="tableContent">{{ i.accountNo }}</td>
            <td class="tableContent no-wrap">{{ i.createdOn }}</td>
            <td class="text-center tableContent">{{ i.payoutReason }}</td>
            <td
              class="text-left tableContent input_text1"
              @click="callDialog(i.request)"
            >
              {{ i.request }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div
        v-else-if="isSubmit"
        class="d-flex justify-center secondaryColor pa-8"
      >
        No Data Found
      </div>
      <div
        v-if="payOutDetails.length != 0"
        class="d-flex align-center justify-end pa-4"
      >
        <v-pagination
          v-model="page"
          :length="length"
          :total-visible="7"
          circle
        ></v-pagination>
      </div>
    </div>
    <v-dialog
      class="json-dialog"
      v-model="dialog"
      max-width="600"
      style="overflow-x: hidden"
    >
      <v-card>
        <div
          class="d-flex dialog-header"
          style="
            justify-content: space-between;
            border-bottom: -16px solid black;
          "
        >
          <div class="font-weight-bold fsize14 mx-6">JSON</div>
          <div>
            <v-btn
              height="28"
              depressed
              outlined
              text
              class="text-capitalize fsize12"
              @click="formatFlag ? format() : ''"
            >
              Format</v-btn
            >
            <v-btn
              height="28"
              depressed
              outlined
              text
              :color="copyText == 'Copy' ? 'black' : 'green'"
              class="text-capitalize fsize12 mx-4"
              @click="copyTextFunc()"
              >{{ copyText }}</v-btn
            >
            <v-icon @click="dialog = false" color="primary"
              >mdi-close-circle</v-icon
            >
          </div>
        </div>
        <div
          v-if="formatFlag"
          class="fsize14 line-height-2 pa-4"
          id="jsonData"
          ref="jsonData"
        >
          {{ currentJson }}
        </div>
        <div v-else>
          <json-viewer :value="currentJson" sort></json-viewer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import JsonViewer from "vue-json-viewer";
// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer);

import { mapGetters } from "vuex";
import commonFunc from "../../mixins/commonFunctions";
export default {
  mixins: [commonFunc],

  data: () => ({
    status: "All",
    fromDate: null,
    toDate: null,
    dateMenu1: false,
    dateMenu2: false,
    formValid: true,
    page: 1,
    length: 10,
    //
    copyText: "copy",
    currentJson: [],
    dialog: false,
    formatFlag: true,
    //
    fromDateRules: [(v) => !!v || "From Date is required"],
    toDateRules: [(v) => !!v || "To Date is required"],
    statusRules: [(v) => !!v || "Status is required"],
    typeRules: [(v) => !!v || "Type is required"],

    payinHeaders: [
      {
        text: "User Id",
        value: "userId",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Status",
        value: "paymentStatus",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Amount",
        value: "amount",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Payment Mode",
        value: "paymentMethod",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Exchange Segment",
        value: "segment",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "AC Number",
        value: "accountNo",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Order Id",
        value: "orderId",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Date",
        value: "createdOn",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Request",
        value: "request",
        align: " input_text tableContent",
        sortable: false,
      },
    ],
    payOutHeaders: [
      {
        text: "User Id",
        value: "userId",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Amount",
        value: "amount",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Exchange Segment",
        value: "segment",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "AC Number",
        value: "accountNo",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Date",
        value: "createdOn",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "payOut Reason",
        value: "payoutReason",
        align: "tableContent",
        sortable: false,
      },
      {
        text: "Request",
        value: "request",
        align: " input_text tableContent",
        sortable: false,
      },
    ],
    payType: "payIn",
    userId: "",
    fromAmount: "",
    toAmount: "",
    paymentMode: "",
    exchangeSegment: "",
    paymentMethod: "",
    isSubmit: false,
  }),

  computed: {
    ...mapGetters("payment", {
      statusList: "getStatusList",
      paymentDetails: "getPaymentDetails",
      payOutDetails: "getPayoutDetails",
      paginationCount: "getPaginationCount",
    }),
    ...mapGetters({
      loading: "getLoader",
    }),
    computedDateFormatted1: {
      get() {
        return this.VutifyFormatDate(this.fromDate,'/');
      },
      set() {},
    },
    computedDateFormatted2: {
      get() {
        return this.VutifyFormatDate(this.toDate,'/');
      },
      set() {},
    },
  },

  watch: {
    page: function (val) {
      this.pageRoute(val == 1 ? 0 : (val - 1) * 10);
    },
    dialog(val) {
      this.formatFlag = true;
    },
  },

  created() {
    this.$store.dispatch("payment/getStatusList");
  },

  methods: {
    async getPaymentDetails() {
      if (this.$refs.form.validate()) {
        if (this.payType == "payIn") {
          this.page = 1;
          let json = {
            paymentStatus: this.status == "All" ? "%" : this.status,
            fromDate: this.fromDate,
            toDate: this.toDate,
            offSet: 0,
            userId: this.userId ? this.userId : "%",
            fromAmount: this.fromAmount ? this.fromAmount : "0",
            toAmount: this.toAmount ? this.toAmount : "99999999",
            exchSeg: this.exchangeSegment ? this.exchangeSegment : "%",
            paymentMethod: this.paymentMode ? this.paymentMode : "%",
          };
          let json1 = {
            paymentStatus: this.status == "All" ? "%" : this.status,
            fromDate: this.fromDate,
            toDate: this.toDate,
            userId: this.userId ? this.userId : "%",
            fromAmount: this.fromAmount ? this.fromAmount : "0",
            toAmount: this.toAmount ? this.toAmount : "99999999",
            exchSeg: this.exchangeSegment ? this.exchangeSegment : "%",
            paymentMethod: this.paymentMode ? this.paymentMode : "%",
          };
          this.$store.dispatch("payment/getPaymentDetails", json);
          // this.$store.dispatch("payment/payInDownload", json1);
        } else if (this.payType == "payOut") {
          this.page = 1;
          let jsonData = {
            fromDate: this.fromDate,
            toDate: this.toDate,
            offSet: 0,
            userId: this.userId ? this.userId : "%",
            fromAmount: this.fromAmount ? this.fromAmount : "0",
            toAmount: this.toAmount ? this.toAmount : "99999999",
            exchSeg: this.exchangeSegment ? this.exchangeSegment : "%",
          };
          let jsonData1 = {
            fromDate: this.fromDate,
            toDate: this.toDate,
            userId: this.userId ? this.userId : "%",
            fromAmount: this.fromAmount ? this.fromAmount : "0",
            toAmount: this.toAmount ? this.toAmount : "99999999",
            exchSeg: this.exchangeSegment ? this.exchangeSegment : "%",
          };
          this.$store.dispatch("payment/getPayoutDetails", jsonData);
          // this.$store.dispatch("payment/payOutDownload", jsonData1);
        }
      }
    },
    pageRoute(offset) {
      if (this.$refs.form.validate()) {
        if (this.payType == "payIn") {
          let json = {
            paymentStatus: this.status == "All" ? "%" : this.status,
            fromDate: this.fromDate,
            toDate: this.toDate,
            offSet: offset,
            userId: this.userId ? this.userId : "%",
            fromAmount: this.fromAmount ? this.fromAmount : '0',
            toAmount: this.toAmount ? this.toAmount : '99999999',
            exchSeg: this.exchangeSegment ? this.exchangeSegment : '%',
            paymentMethod: this.paymentMode ? this.paymentMode : '%'
          };
          this.$store.dispatch("payment/getPaymentDetails", json);
        } else if (this.payType == "payOut") {
          let jsonData = {
            fromDate: this.fromDate,
            toDate: this.toDate,
            offset: offset,
            userId: this.userId ? this.userId : "%",
            fromAmount: this.fromAmount ? this.fromAmount : '0',
            toAmount: this.toAmount ? this.toAmount : '99999999',
            exchSeg: this.exchangeSegment ? this.exchangeSegment : '%',
          };
          this.$store.dispatch("payment/getPayoutDetails", jsonData);
        }
      }
      if(this.page == this.length) {
        this.length +=1
      }
    },

    callDialog(data) {
      this.copyText = "Copy";
      data && data != `""` ? (this.dialog = true) : "";
      this.currentJson = data;
    },

    format() {
      this.formatFlag ? (this.currentJson = JSON.parse(this.currentJson)) : "";
      this.formatFlag = !this.formatFlag;
    },

    copyTextFunc() {
      navigator.clipboard.writeText(JSON.stringify(this.currentJson));
      this.copyText = "Copied";
      setTimeout(() => {
        this.copyText = "Copy";
      }, 2500);
    },

    resetData() {
      if (this.$refs.form.validate()) {
        this.isSubmit = false;
        this.$store.commit("payment/SET_PAYMENT_DETAILS", []);
        this.$store.commit("payment/SET_PAYOUT_DETAILS", []);
      }
    },

    DownloadNewFormet(value, fileName){
      console.log(value, fileName);
      let json = {
            paymentStatus: this.status == "All" ? "%" : this.status,
            fromDate: this.fromDate,
            toDate: this.toDate,
            offSet: 0,
            userId: this.userId ? this.userId : "%",
            fromAmount: this.fromAmount ? this.fromAmount : '0',
            toAmount: this.toAmount ? this.toAmount : '99999999',
            exchSeg: this.exchangeSegment ? this.exchangeSegment : '%',
            paymentMethod: this.paymentMode ? this.paymentMode : '%'
          };
      this.$store.dispatch("payment/getPaymentDetailsDownload",json)
    //   const modifyData = []
    //   var tableHead = ''
    // for(let item of value){
    //   if(fileName == 'payIn'){
    //      tableHead = {
    //   "User Id" : item.userId,
    //   "Status" : item.paymentStatus,
    //   "Amount" : item.amount,
    //   "Payment Mode" : item.paymentMethod,
    //   "Exchange Segment" : item.segment,
    //   "AC Number" : item.accountNo,
    //   "Order Id" : item.orderId,
    //   "Date" : item.createdOn,
    //   "Request" :  `"${item.request}"`

    // }    
    //   }
    //  if(fileName == 'payOut'){
    //      tableHead = {
    //   "User Id" : item.userId,
    //   "Amount" : item.amount,
    //   "Exchange Segment" : item.segment,
    //   "AC Number" : item.accountNo,
    //   "Date" : item.createdOn,
    //   "payOut Reason" : item.payoutReason,
    //   "Request" : item.request
      
    // }    
    //   }
    //   modifyData.push(tableHead)
    // }

    // // get keys as array
    //             const keys = Object.keys(modifyData[0]);
    //             const commaSeparatedString = [keys.join(",") , modifyData.map(row => keys.map(key => row[key]).join(",")).join("\n")].join("\n")
    //             const csvBlob = new Blob([commaSeparatedString])

    //             const a2 = document.createElement('a');
    //             var tempFileName =  fileName + '.csv'
    //             a2.download = tempFileName
    //             a2.href = URL.createObjectURL(csvBlob)
    //             a2.target = '_blank';
    //         a2.click();
    
    }
  }
};
</script>
